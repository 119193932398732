import React from "react";
import "../style.scss";
import "../App.css";
import { useTranslation } from "react-i18next";

import footerLine from "../assets/images/footerLine.png";
import youtube from "../assets/images/you.png";
import insta from "../assets/images/insta.png";
import linkedin from "../assets/images/linkedin.png";
import vafa from "../assets/images/vafaLogo.png"
import cam from "../assets/images/camLogo.png"
import whatsapp from "../assets/images/whatsapp.png"
import x from "../assets/images/xicon.png"
import facebook from "../assets/images/faceicon.png"


function Footer() {

  const { t } = useTranslation();

  return (
    <div className="footer">
      <div className="footer-top">
        <div className="top-left">
          <h3> {t("contact_us")} </h3>
          <img src={footerLine} alt="" />
        </div>
        <h2>{t("footer_communication")} </h2>
      </div>
      <div className="footerMid">
        <div className="midItem">
          <h2>{t("footer_center")}</h2>
          <p>Gazi Mustafa Kemal Bulvarı No:137 06570 Çankaya / ANKARA</p>
        </div>
        <div className="midItem">
          <h2>{t("footer_branch")}</h2>
          <p>
            Esenboğa Havalimanı Özel Hangarlar Bölgesi 325/77 Nolu Hangar Çubuk
            / ANKARA
          </p>
        </div>
        <div className="midItem">
          <h2>{t("footer_salt")}</h2>
          <p>Kapadokya Havaalanı Girişi 509000 Gülşehir / NEVŞEHİR</p>
        </div>
      </div>
      <div className="footerMid2">
        <p>info@vizyonhavacilik.com.tr</p>
        <p>info@vafaaero.com </p>
      </div>
      <div className="footerMid2">
        <p>0 (312) 384 48 66</p>
        <p>444 95 92 </p>
        <p>0 539 842 46 49</p>
      </div>
      <div className="footerLinks">
        <a href="https://www.youtube.com/channel/UCA06mKjNYhOBiUMD7Gs0WYw" target="_blank" rel="noreferrer" >
          <img src={youtube} alt="" />
        </a>
        <a href="https://www.instagram.com/vafa_aero/" target="_blank" rel="noreferrer" >
          <img src={insta} alt="" />
        </a>
        <a href="https://www.linkedin.com/in/vafa-aero-0ab63a234/" target="_blank" rel="noreferrer" >
          <img src={linkedin} alt="" />
        </a>
        <a href="https://x.com/vafa_aero?t=jiNI2D2kXutYyaSDIxNV2g&s=09" target="_blank" rel="noreferrer" >
          <img src={x} alt="" />
        </a>
        <a href="https://www.facebook.com/profile.php?id=100086736336153&mibextid=ZbWKwL" target="_blank" rel="noreferrer" >
          <img src={facebook} alt="" />
        </a>
        <a href="https://wa.me/905398424649" target="_blank" rel="noreferrer" >
          <img src={whatsapp} alt="" />
        </a>
       
      </div>
      <div className="footerBottom">
        <img src={vafa} alt="" />
        
        <img src={cam} alt="" />
      </div>
      <div className="camFooter" >
      <p>VAFA bir <a target="_blank" href="https://www.vizyonhavacilik.com/">VİZYON HAVACILIK A.Ş</a> Markasıdır.</p>
      </div>
      <div className="camFooter1">
      <p>VİZYON HAVACILIK bir <a target="_blank" href="https://www.camgrup.org/">ÇAM GRUP</a> Firmasıdır.</p>
      </div>
      <div className="camFooter2" >
      <p><a target="_blank" href="https://www.altinyapim.com/">ALTIN YAPIM FİLM ORGANİZASYON VE BİLİŞİM A.Ş.</a> Telif Hakları Saklıdır.</p>
      </div>
      
    </div>
    
  );
  
}

export default Footer;
