import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Header from "../Components/Header";
import Footer from "../Components/Footer";
import WhatsAppButton from '../Components/WhatsAppButton';
import Card from "../Components/Pricingcard";
import FullScreenImage from '../Components/FullScreen';


import foto from "../assets/images/alsim1.jpg";
import videoSrc from "../assets/galeri/vafa.mp4";
import foto1 from "../assets/galeri/1.jpeg";
import foto2 from "../assets/galeri/2.jpeg";
import foto3 from "../assets/galeri/3.jpeg";
import foto4 from "../assets/galeri/4.jpeg";
import foto5 from "../assets/galeri/5.jpeg";
import foto6 from "../assets/galeri/6.jpeg";
import foto7 from "../assets/galeri/7.jpeg";
import foto8 from "../assets/galeri/8.jpeg";
import foto9 from "../assets/galeri/9.jpeg";
import foto10 from "../assets/galeri/10.jpeg";


import "../style.scss";

function Galeri() {

    const { t } = useTranslation();

    const [selectedImageIndex, setSelectedImageIndex] = useState(null);
    const images = [foto1, foto2, foto3, foto4, foto5, foto6, foto7, foto8, foto9, foto10];

    const handleImageClick = (index) => {
        setSelectedImageIndex(index);
    };

    const handlePrevImage = () => {
        setSelectedImageIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
    };

    const handleNextImage = () => {
        setSelectedImageIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
    };

    const handleCloseFullScreen = () => {
        setSelectedImageIndex(null);
    };

    return (
        <div className='home-container'>
            <Header />
            <h2 className='gallery-title'>{t("galeri_header")}</h2>
            <div className="gallery-container">
                <div className="gallery">
                <div className="video-container">
                <video src={videoSrc} autoPlay loop muted controls />
            </div>
                    {images.map((image, index) => (
                        <div className="gallery-item" key={index} onClick={() => handleImageClick(index)}>
                            <img src={image} alt="" />
                        </div>
                    ))}
                </div>
            </div>
            
            

            <WhatsAppButton />
            <Footer />

            {selectedImageIndex !== null && (
                <FullScreenImage
                    imageUrl={images[selectedImageIndex]}
                    onNext={handleNextImage}
                    onPrev={handlePrevImage}
                    onClose={handleCloseFullScreen}
                />
            )}
        </div>
    );
}

export default Galeri;