import React from 'react';

function FullScreenImage({ imageUrl, onNext, onPrev, onClose }) {
    return (
        <div className="full-screen-overlay">
            <div className="full-screen-image">
                <span className="arrow arrow-left" onClick={onPrev}>&#10094;</span>
                <img src={imageUrl} alt="Full Screen" />
                <span className="arrow arrow-right" onClick={onNext}>&#10095;</span>
                <span className="close-button1" onClick={onClose}>X</span>
            </div>
        </div>
    );
}

export default FullScreenImage;
