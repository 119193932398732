import React from 'react'

import linehori from "../assets/images/linehori.png"

import "../App.css"
import "../style.scss"

export default function Graduatescard(props) {
  return (
    <div className='graduates-card'>
      {/* <img src={props.img} alt="" /> */}
      <div className='graduates-card-info'>
        <h5>{props.noun}</h5>
        {/* <img src={linehori} alt="" />
        <h5>{props.date}</h5> */}
        <img src={linehori} alt="" />
        <h5>{props.plane}</h5>
      </div>
      {/* <h1>Görüş</h1>
      <p>
        {props.exp}
      </p> */}
    </div>
  )
}
