import React, { useEffect } from "react";

import "./App.css";
import Home from "./Pages/Home";
import Ppl from "./Pages/academyInfo/Ppl";
import Night from "./Pages/academyInfo/Night";
import Pic from "./Pages/academyInfo/Pic";
import Atp from "./Pages/academyInfo/Atp";
import Ir from "./Pages/academyInfo/Ir";
import Cpl from "./Pages/academyInfo/Cpl";
import Me from "./Pages/academyInfo/Me";
import Tbo from "./Pages/academyInfo/Tbo";
import Mcc from "./Pages/academyInfo/Mcc";
import Simulation from "./Pages/services/Simulation";
import PicService from "./Pages/services/Picservice";
import Housing from "./Pages/services/Housing";
import Cesna from "./Pages/fleet/Cesna";
import Arrow from "./Pages/fleet/Arrow";
import Seminole from "./Pages/fleet/Seminole";
import Tecnam from "./Pages/fleet/Tecnam";
import Alsima from "./Pages/fleet/Alsimalx";
import Graduates from "./Pages/gaduates";
import Bloghome from "./Pages/blog/Bloghome";
import Blogone from "./Pages/blog/Blog1";
import Blogtwo from "./Pages/blog/Blog2";
import Blogthree from "./Pages/blog/Blog3";
import Blogfour from "./Pages/blog/Blog4";
import Blogfive from "./Pages/blog/Blog5";
import Pricing from "./Pages/Pricing";
import Galeri from "./Pages/galeri";
import Contact from "./Pages/Contact";
import { Route, Routes, useLocation } from "react-router-dom";
import "./style.scss";

function App() {

  const { pathname } = useLocation();

  useEffect(() => {
    if (typeof windows === "undefined") {
      window.scrollTo(0, 0);
    }
  }, [pathname]);

  return (
    <div className="App">
      <Routes >
        <Route path="/" element={<Home />} />
        <Route path="/Ppl" element={<Ppl />} />
        <Route path="/Night" element={<Night />} />
        <Route path="/Pic" element={<Pic />} />
        <Route path="/Atp" element={<Atp />} />
        <Route path="/Ir" element={<Ir />} />
        <Route path="/Cpl" element={<Cpl />} />
        <Route path="/Me" element={<Me />} />
        <Route path="/PicService" element={<PicService />} />
        <Route path="/Simulation" element={<Simulation />} />
        {/* <Route path="/Housing" element={<Housing />} /> */}
        <Route path="/Cesna" element={<Cesna />} />
        <Route path="/Arrow" element={<Arrow />} />
        <Route path="/Seminole" element={<Seminole />} />
        <Route path="/Tecnam" element={<Tecnam />} />
        <Route path="/Alsima" element={<Alsima />} />
        <Route path="/Graduates" element={<Graduates />} />
        <Route path="/Bloghome" element={<Bloghome />} />
        <Route path="/Blogone" element={<Blogone />} />
        <Route path="/Blogtwo" element={<Blogtwo />} />
        <Route path="/Blogthree" element={<Blogthree />} />
        <Route path="/Blogfour" element={<Blogfour />} />
        <Route path="/Blogfive" element={<Blogfive />} />
        <Route path="/Pricing" element={<Pricing />} />
        <Route path="/Galeri" element={<Galeri />} />
        <Route path="/Tbo" element={<Tbo />} />
        <Route path="/Mcc" element={<Mcc />} />
        <Route path="/Contact" element={<Contact />} />
        
      </Routes>

    </div>
  );
}

export default App;
