import React from 'react'
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';

import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import WhatsAppButton from '../../Components/WhatsAppButton';

import Alsim1 from '../../assets/images/alsim1.jpg';
import Alsim2 from '../../assets/images/alsim2.jpg';
import Alsim3 from '../../assets/images/alsim3.jpg';
import Alsim4 from '../../assets/images/alsim4.jpeg';

import "../../App.css";
import "../../style.scss";

function Alsimalx() {

    const { t } = useTranslation();

    return (
        
        <div className='fleet-main'>
            <Header />
            <motion.div className='fleet-exp-con'
                initial={{
                    y: 100,
                    opacity: 0.2,

                }}
                whileInView={{
                    y: 1,
                    opacity: 1,
                }}
                transition={{
                    type: "spring",
                    stiffness: 50,
                    damping: 10,
                    duration: 2
                }}
            >
                
                <h1>{t("alsima_header")}</h1><br/>
                <div className='alsimImage' >
                <img src={Alsim2} alt="uçak" />
                <img src={Alsim1} alt="uçak" />
                </div>
                <br/><br/>
                <div className='alsimImageBig' >              
                <img src={Alsim4} alt="uçak" />
                </div>
                
               <div className='alsimaTit'>
               <p>
                    {t("alsima_title_1")} <br/>
                    {t("alsima_title_2")} <br/>
                    {t("alsima_title_3")} <br/>
                    {t("alsima_title_4")} <br/>
                    {t("alsima_title_5")} <br/>
                    {t("alsima_title_6")} <br/>
                    {t("alsima_title_7")} <br/>
                    {t("alsima_title_8")} <br/>
                    {t("alsima_title_9")} <br/>
                    {t("alsima_title_10")} <br/>
                    {t("alsima_title_11")} <br/>
                    {t("alsima_title_12")} <br/>
                    {t("alsima_title_13")} <br/>
                    {t("alsima_title_14")} <br/>
                    {t("alsima_title_15")} <br/>
                    {t("alsima_title_16")} <br/>
                    {t("alsima_title_17")} <br/>
                    {t("alsima_title_18")} <br/>

                </p>
               </div>
            </motion.div>
            <WhatsAppButton />
            <Footer />
        </div>
    )
}

export default Alsimalx