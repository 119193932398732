import React from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';

import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import WhatsAppButton from '../../Components/WhatsAppButton';

import Cesnaimg from '../../assets/images/fleetcessna.png';

import "../../App.css";
import "../../style.scss";

function Cessna() {

    const { t } = useTranslation();

    return (
        <div className='fleet-main'>
            <Header />
            <motion.div className='fleet-exp-con'
                initial={{
                    y: 100,
                    opacity: 0.2,

                }}
                whileInView={{
                    y: 1,
                    opacity: 1,
                }}
                transition={{
                    type: "spring",
                    stiffness: 50,
                    damping: 10,
                    duration: 2
                }}
            >
                <h1>Cessna 172S Skyhawk  </h1>
                <img src={Cesnaimg} alt="uçak" />
                <p className='TopFeatureFl'>
                    {t("cesna_title_10")} <br />
                    {t("cesna_title_11")} <br />
                    {t("cesna_title_12")} <br />
                    {t("cesna_title_13")} <br />
                </p>
                <p>
                    {t("cesna_title_1")} <br />
                    {t("cesna_title_2")} <br />
                    {t("cesna_title_3")} <br />
                    {t("cesna_title_4")} <br />
                    {t("cesna_title_5")} <br />
                    {t("cesna_title_6")} <br />
                    {t("cesna_title_7")} <br />
                    {t("cesna_title_8")} <br />
                    {t("cesna_title_9")} <br />
                </p>
            </motion.div>
            <WhatsAppButton />
            <Footer />
        </div>
    )
}

export default Cessna