import React from 'react'

function Loadingmark() {
    return (
        <div class="loader">
            <div class="loader-circle"></div>
            <span class="loader-text">Loading...</span>
        </div>
    )
}

export default Loadingmark