import React from 'react'
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';

import Header from '../../Components/Header'
import Footer from '../../Components/Footer'
import WhatsAppButton from '../../Components/WhatsAppButton';

import blogoneimg from '../../assets/images/blogoneimg.jpg'

import '../../App.css'
import '../../style.scss'

function Blog1() {

    const { t } = useTranslation();

    return (
        <div className='blogs' >
            <Header />
            <motion.div className='blogs-main'
                initial={{
                    y: 100,
                    opacity: 0.2,
                }}
                whileInView={{
                    y: 1,
                    opacity: 1,
                }}
                transition={{
                    type: "spring",
                    stiffness: 50,
                    damping: 10,
                    duration: 2
                }}>
                <div className='blogs-head'>
                    <h1>{t("Blogone_header")}</h1>
                </div>
                <img src={blogoneimg} alt="" />
                <div className='blogs-exp'><p>
                    {t("Blogone_title_1")} <br /> <br />
                    {t("Blogone_title_2")} <br /> <br />
                    {t("Blogone_title_3")} <br /> <br />
                    {t("Blogone_title_4")} <br /> <br />
                    {t("Blogone_title_5")} <br /> <br />
                    {t("Blogone_title_6")} <br /> <br />
                    {t("Blogone_title_7")} <br /> <br />
                    {t("Blogone_title_8")} <br /> <br />
                    {t("Blogone_title_9")} <br /> <br />
                    {t("Blogone_title_10")} <br /> <br />
                    {t("Blogone_title_11")} <br /> <br />
                    {t("Blogone_title_12")} <br /> <br />
                    {t("Blogone_title_13")} <br /> <br />
                    {t("Blogone_title_14")} <br /> <br />
                    {t("Blogone_title_15")} <br /> <br />
                    {t("Blogone_title_16")} <br /> <br />
                    {t("Blogone_title_17")} <br /> <br />
                    {t("Blogone_title_18")} <br /> <br />
                    {t("Blogone_title_19")} <br /> <br />
                    {t("Blogone_title_20")} <br /> <br />
                    {t("Blogone_title_21")} <br /> <br />
                    {t("Blogone_title_22")} <br /> <br />
                    {t("Blogone_title_23")} <br /> <br />
                    {t("Blogone_title_24")} <br /> <br />
                    {t("Blogone_title_25")} <br /> <br />
                    {t("Blogone_title_26")} <br /> <br />
                    {t("Blogone_title_27")} <br /> <br />
                    {t("Blogone_title_28")} <br /> <br />
                    {t("Blogone_title_29")} <br /> <br />
                    {t("Blogone_title_30")} <br /> <br />
                </p></div>
            </motion.div>
            <WhatsAppButton />
            <Footer />
        </div>
    )
}

export default Blog1