import React from 'react'
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import WhatsAppButton from '../../Components/WhatsAppButton';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';

import simImg from '../../assets/images/simulation.png';
import simlogImg from '../../assets/images/simLogimg.png';


import "../../App.css";
import "../../style.scss";

function Simulation() {

    const { t } = useTranslation();

    return (
        <div className='services-main-con'>
            <Header />
            <div className='service-img-exp-con'>
                <motion.div className='services-img-con'
                    initial={{
                        opacity: 0.2,
                        x: -700
                    }}
                    animate={{
                        opacity: 1,
                        x: 0
                    }}
                    transition={{
                        type: "spring",
                        stiffness: 40,
                        damping: 20,
                        transition: 5,

                    }}
                >
                    <img src={simImg} alt="simulasyon" />
                </motion.div>
                <motion.div className='services-exp-con'
                    initial={{
                        opacity: 0.2,
                        x: 700,
                    }}
                    animate={{
                        opacity: 1,
                        x: 0,
                    }}
                    transition={{
                        type: "spring",
                        stiffness: 40,
                        damping: 20,
                        transition: 5,

                    }}
                >
                    <div className='service-exp-main'>
                        <div className='service-exp-head'>
                            <h1>{t("simulation_alsim_header")}</h1>
                        </div>
                        <div className='service-exp-log'>
                            <motion.img src={simlogImg} alt=""
                                initial={{
                                    rotateZ: -20,
                                }}
                                animate={{
                                    rotateZ: 20,
                                }}
                                transition={{
                                    repeat: Infinity,
                                    repeatType: 'reverse',
                                    repeatDelay: 0.15,
                                    duration: 1,
                                }}
                            />
                            <p>{t("simulation_alsim_title")}</p>
                        </div>
                    </div>
                </motion.div>
            </div>
            <WhatsAppButton />
            <Footer />
        </div>
    )
}

export default Simulation