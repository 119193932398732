import React from 'react'
import { useTranslation } from 'react-i18next'

import Header from "../Components/Header"
import Footer from "../Components/Footer"
import WhatsAppButton from '../Components/WhatsAppButton';
import Card from "../Components/Pricingcard"

import "../style.scss"

function Pricing() {

    const { t } = useTranslation();

    /* const priceInfo = [
         {
             head: "PPL (A) HUSUSİ PİLOT LİSANSI",
             time: " 4 Ay",
             edu: " 118 Saat",
             fly: " 46.30 Saat",
             pricing: " 13.500 EURO"
         },
         {
             head: null,
             time: " 2 Gün",
             edu: " 2 Saat",
             fly: " 5 Saat",
             pricing: " 2000 EURO"
         },
         {
             head: "PIC UÇUŞU",
             time: " 15 Gün",
             edu: null,
             fly: " 88 Saat",
             pricing: " 130 EURO (SAAT)"
         },
         {
             head: "ATP(A) TEORİK BİLGİ EĞİTİMİ",
             time: " 4 Ay",
             edu: " 650 Saat",
             fly: null,
             pricing: " 1500 EURO"
         },
         {
             head: "IR(A) ALETLİ UÇUŞ EĞİTİMİ",
             time: " 1.5 Ay",
             edu: "4 Saat",
             fly: " 51 Saat",
             pricing: " 9500 EURO"
         },
         {
             head: "CPL(A) PİLOT LİSANSI",
             time: " 1 Ay",
             edu: null,
             fly: " 16.30 Saat",
             pricing: " 6000 EURO"
         },
         {
             head: "ME(A) ÇOK MOTOR EĞİTİMİ",
             time: " 1 Ay",
             edu: " 18 Saat",
             fly: " 13 Saat",
             pricing: " 9500 EURO"
         },
     ]*/

    return (
        <div className='pricing'>
            <Header />
            <div className='pricing-main-con'>
                <div className='pricing-two-hori'>
                    <Card
                        head={t("ppl_price_header")}
                        time={t("ppl_duration")}
                        edu={t("ppl_ground_training")}
                        fly={t("ppl_flight_training")}
                        pricing={t("ppl_price")}
                    ></Card>
                    <Card
                        head={t("night_info_header")}
                        time={t("night_duration")}
                        edu={t("night_ground_training")}
                        fly={t("night_flight_training")}
                        pricing={t("night_price")}
                    ></Card>
                </div>
                <div className='pricing-two-hori'>
                    <Card
                        head={t("pic_price_header")}
                        time={t("pic_duration")}
                        edu={t("pic_ground_training")}
                        fly={t("pic_flight_training")}
                        pricing={t("pic_price")}
                    ></Card>
                    <Card
                        head={t("atp_price_header")}
                        time={t("atp_duration")}
                        edu={t("atp_ground_training")}
                        fly={t("atp_flight_training")}
                        pricing={t("atp_price")}
                    ></Card>
                </div>
                <div className='pricing-two-hori'>
                    <Card
                        head={t("ir_price_header")}
                        time={t("ir_duration")}
                        edu={t("ir_ground_training")}
                        fly={t("ir_flight_training")}
                        pricing={t("ir_price")}
                    ></Card>
                    <Card
                        head={t("cpl_price_header")}
                        time={t("cpl_duration")}
                        edu={t("cpl_ground_training")}
                        fly={t("cpl_flight_training")}
                        pricing={t("cpl_price")}
                    ></Card>
                </div>
                <div className='pricing-two-hori'>
                    <Card
                        head={t("me_price_header")}
                        time={t("me_duration")}
                        edu={t("me_ground_training")}
                        fly={t("me_flight_training")}
                        pricing={t("me_price")}
                    ></Card>
                </div>
            </div>
            <WhatsAppButton />
            <Footer />
        </div>
    )
}

export default Pricing