import React from 'react';
import { useTranslation } from 'react-i18next';

import "../../App.css";
import "../../style.scss";

import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import WhatsAppButton from '../../Components/WhatsAppButton';

import plane from "../../assets/images/small-plane.png";

function Ppl() {

    const { t } = useTranslation();

    return (
        <div className='main-con-ppl'>
            <Header />
            <h1 className='ppl-head'>{t("ppl_info_header")}</h1>
            <div className='ppl-exp'>
                <div className='exp-img-con'>
                    <img src={plane} alt="uçak" />
                </div>
                <div className='exp-main-con'>
                    <h1>{t("ppl_info_subheader1")}</h1>
                    <p>{t("ppl_info_subtitle1")}</p>
                </div>
            </div>
            <div className='ppl-exp'>
                <div className='exp-img-con'>
                    <img src={plane} alt="uçak" />
                </div>
                <div className='exp-main-con'>
                    <h1>{t("ppl_info_subheader2")}</h1>
                    <p>{t("ppl_info_subtitle2_1")}
                        <br />
                        {t("ppl_info_subtitle2_2")}
                        <br />
                        {t("ppl_info_subtitle2_3")}
                    </p>
                </div>
            </div>
            <div className='ppl-exp'>
                <div className='exp-img-con'>
                    <img src={plane} alt="uçak" />
                </div>
                <div className='exp-main-con'>
                    <h1>{t("ppl_info_subheader3")}</h1>
                    <p>{t("ppl_info_subtitle3_1")} <br />
                        {t("ppl_info_subtitle3_2")} <br />
                        {t("ppl_info_subtitle3_3")} <br />
                        {t("ppl_info_subtitle3_4")} <br />
                        {t("ppl_info_subtitle3_5")} <br />
                    </p>
                </div>
            </div>
            <div className='ppl-exp'>
                <div className='exp-img-con'>
                    <img src={plane} alt="uçak" />
                </div>
                <div className='exp-main-con'>
                    <h1>{t("ppl_info_subheader4")}</h1>
                    <h1>{t("ppl_info_subtitle4")}</h1>
                    <p>
                        {t("ppl_info_subtitle5_1")} <br />
                        {t("ppl_info_subtitle5_2")} <br />
                        {t("ppl_info_subtitle5_3")} <br />
                        {t("ppl_info_subtitle5_4")} <br />
                        {t("ppl_info_subtitle5_5")} <br />
                        {t("ppl_info_subtitle5_6")} <br />
                        {t("ppl_info_subtitle5_7")} <br />
                        {t("ppl_info_subtitle5_8")} <br />
                        {t("ppl_info_subtitle5_9")} <br />
                        {t("ppl_info_subtitle5_10")} <br />
                        {t("ppl_info_subtitle5_11")} <br />
                    </p>
                </div>
            </div>
            <WhatsAppButton />
            <Footer />
        </div>
    )
}

export default Ppl;