import React from 'react'
import { useTranslation } from 'react-i18next'

function Pricingcard(props) {

    const { t } = useTranslation();

    return (
        <div className='pricing-card'>
            <div className='pricing-card-header'>
                <h1> {props.head} </h1>
            </div>
            <div className='pricing-card-body'>
                <div className='pricing-card-body-left'>
                    <p>{t("duration")} &nbsp;  {props.time} </p>
                    <p>{t("ground_training")} &nbsp; {props.edu} </p>
                    <p>{t("flight_training")} &nbsp; {props.fly} </p>
                </div>
            </div>
        </div>
    )
}

export default Pricingcard