import { useRef } from "react";
import emailjs from "@emailjs/browser";
import { useTranslation } from "react-i18next";

import Header from "../Components/Header.js";
import Footer from "../Components/Footer.js";
import WhatsAppButton from '../Components/WhatsAppButton';



const Contact = () => {
    const form = useRef();
    const { t } = useTranslation();
  
    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs
          .sendForm("service_h1fdwn6", "template_i3v8vmo", form.current, {
            publicKey: "dAhrt-PGScG6fg9_m",
          })
          .then(
            () => {
              console.log("SUCCESS!");
            },
            (error) => {
              console.log("FAILED...", error.text);
            }
          );
        e.target.reset();
      };
    return (
        
        <div className="home-container">
        <Header />
          
          <form ref={form} onSubmit={sendEmail} className="form-container form-control">
          <h2 className="form-text">{t("form_header")}</h2>
          <br/>
            <input type="text"  placeholder={`${t("form_name")}`}  name="user_name" required />
            <input type="email"  placeholder={`${t("form_mail")}`} name="user_email" required />
            <input type="text"  placeholder={`${t("form_phone")}`} name="user_no" required />
            
            <select name="department" required>
              <option value="">{t("select_btn")}</option>
              <option value="PPL(HUSUSİ PİLOT LİSANSI)">PPL(HUSUSİ PİLOT LİSANSI)</option>
              <option value="ATPL">ATPL</option>
              <option value="PIC">PIC</option>
              <option value="CPL">CPL</option>
              <option value="ME-IR">ME-IR</option>
              <option value="FI">FI</option>
              <option value="GECE NR">GECE NR</option>
              <option value="MCC">MCC</option>
              <option value="TEORİK BİLGİ ÖĞRETMENLİĞİ">TEORİK BİLGİ ÖĞRETMENLİĞİ</option>
            </select>
            
            <textarea name="message" placeholder={`${t("form_subject")} `} required></textarea>
            
            <button type="submit">{t("submit_btn")}</button>
          </form>
          <WhatsAppButton />
      <Footer />
        </div>
     
      
    );
  };
  
  export default Contact;