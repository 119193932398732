import React from 'react'
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';

import Header from '../../Components/Header'
import Footer from '../../Components/Footer'
import WhatsAppButton from '../../Components/WhatsAppButton';

import blogfiveimg from '../../assets/images/blog5img.jpg'

function Blog5() {

    const { t } = useTranslation();

    return (
        <div className='blogs'>
            <Header />
            <motion.div className='blogs-main'
                initial={{
                    y: 100,
                    opacity: 0.2,
                }}
                whileInView={{
                    y: 1,
                    opacity: 1,
                }}
                transition={{
                    type: "spring",
                    stiffness: 50,
                    damping: 10,
                    duration: 2
                }}>
                <div className='blogs-head'>
                    <h1>{t("Blogfive_header")}</h1>
                </div>
                <img src={blogfiveimg} alt="" />
                <div className='blogs-exp'><p>
                    {t("Blogfive_title_1")} <br /> <br />
                    {t("Blogfive_title_2")} <br /> <br />
                    {t("Blogfive_title_3")} <br /> <br />
                    {t("Blogfive_title_4")} <br /> <br />
                    {t("Blogfive_title_5")} <br /> <br />
                    {t("Blogfive_title_6")} <br /> <br />                 
                </p></div>
            </motion.div>
            <WhatsAppButton />
            <Footer />
        </div>
    )
}

export default Blog5