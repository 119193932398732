import React from 'react';
import { useTranslation } from 'react-i18next';

import "../../App.css";
import "../../style.scss";

import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import WhatsAppButton from '../../Components/WhatsAppButton';

import plane from "../../assets/images/small-plane.png";

function Me() {

    const { t } = useTranslation();

    return (
        <div className='main-con-ppl'>
            <Header />
            <h1 className='ppl-head'>{t("mcc_header")}</h1>
            
            <div className='ppl-exp'>
                <div className='exp-img-con'>
                    <img src={plane} alt="uçak" />
                </div>
                <div className='exp-main-con'>
                    <h1>{t("me_info_subheader1")}</h1>
                    <p>{t("mcc_text")}</p>
                </div>
            </div>
            
           
            <WhatsAppButton />
            <Footer />
        </div>
    )
}

export default Me