import React from 'react'
import { Link } from "react-router-dom"
import { useTranslation } from 'react-i18next'

import linehori from '../assets/images/linehori.png'

import youtube from '../assets/Logos/youtube.svg'
import instagram from '../assets/Logos/instagram.svg'
import linkedin from '../assets/Logos/linkedin.svg'

function Blogcard(props) {

    const { t } = useTranslation();

    return (
        <div className='blog-card'>
            <img src={props.img} alt="" />
            <div className='blog-card-head'>
                <img src={linehori} alt="" />
                <h1> {props.head} </h1>
            </div>
            <div className='blog-card-cont-date'>
                <div className='cont-date-logo'>
                    <a href="https://www.youtube.com/channel/UCA06mKjNYhOBiUMD7Gs0WYw" target="_blank" rel="noreferrer"   ><img src={youtube} alt="" /></a>
                    <a href="https://www.instagram.com/vafa_aero/" target="_blank" rel="noreferrer"  ><img src={instagram} alt="" /></a>
                    <a href="https://www.linkedin.com/in/vafa-aero-0ab63a234/" target="_blank" rel="noreferrer"  ><img src={linkedin} alt="" /></a>
                </div>
                <div className='cont-date-con'><p> {props.date} </p></div>
            </div>
            <div className='blog-card-exp'>
                <p> {props.cover} </p>
                <Link to={props.link}><p>{t("continue_button")}</p></Link>
            </div>
            <div className='blog-card-foot'>
                <img src={linehori} alt="" />
                <p>{t("editor")} <strong>&nbsp;{props.editor}</strong> </p>
            </div>
        </div>
    )
}

export default Blogcard